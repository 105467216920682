import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-toggle"
export default class LinkToggleController extends Controller {
  static targets = ["toggler", "togglee"]
  static values = {
    nodes: String
  }

  togglerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "link-toggle#toggle"
    } else {
      target.dataset.action += " link-toggle#toggle"
    }
  }

  toggle(event) {
    event.preventDefault()
    let needToShow = this.toggleeTarget.classList.contains("d-none")

    this.toggleeTargets.forEach((togglee) => {
      if (needToShow) {
        togglee.classList.remove('d-none')
      } else {
        togglee.classList.add('d-none')
      }
      return
    })
  }
}


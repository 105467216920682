import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="class-toggle"
export default class extends Controller {
  static targets = ["toggler", "togglee"]
  static values = {
    nodes: String,
    classes: String
  }

  togglerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "class-toggle#toggle"
    } else {
      target.dataset.action += " class-toggle#toggle"
    }
  }

  toggle(event) {
    event.preventDefault()
    let needToToggle = !this.toggleeTarget.classList.contains("classed")
    const classesToUse = this.classesValue.split(" ")

    this.toggleeTargets.forEach((togglee) => {
      if (needToToggle) {
        classesToUse.forEach(clss => togglee.classList.add(clss))
        togglee.classList.add("classed")
      } else {
        classesToUse.forEach(clss => togglee.classList.remove(clss))
        togglee.classList.remove("classed")
      }
      return
    })
  }
}

import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datePicker"];
  static values = {
    numberOfMonths: { type: Number, default: 1 }
  }

  connect() {
    this.datePickerTargets.forEach(picker => {
      flatpickr(picker, {
        allowInput: false,
        altInput: false,
        // altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        showMonths: this.numberOfMonthsValue
      })
    })
  }
}
